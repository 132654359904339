
import { defineComponent, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import {
  useComments,
  useDeleteComment,
  useEnableComment,
  useDisableComment
} from '@/composables/api';
import { CommentIdOptions } from '@/services/api';

import { CommentStatus } from '@/interfaces/Comment';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import { useI18n } from 'vue-i18n';

const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: 'Status',
    placeholder: 'Please select an option',
    options: [
      {
        label: 'Enable',
        value: CommentStatus.ENABLE
      },
      {
        label: 'Disable',
        value: CommentStatus.DISABLE
      }
    ]
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter
  },
  setup() {
    const page = ref(1);
    const keyword = ref();
    const status = ref();

    const sortBy = ref();
    const descSortBy = ref('updated_at');
    const { t } = useI18n();

    const { data, isLoading, isFetching, refetch } = useComments({ page, keyword, status, sortBy, descSortBy });
    const { isLoading: isDeletedLoading, mutate } = useDeleteComment();
    const { isLoading: isEnableLoading, mutate: enableMutate } =
      useEnableComment();
    const { isLoading: isDisableLoading, mutate: disableMutate } =
      useDisableComment();

    const statusIndex = ref(undefined);
    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      status.value = event[0];
    };
    const deleteComment = ({ commentId }: CommentIdOptions, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          mutate(
            { commentId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Delete successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    const enableComment = ({ commentId }: CommentIdOptions, index: Number) => {
      statusIndex.value = index;
      enableMutate(
        { commentId },
        {
          onSuccess() {
            refetch.value();

            ElMessage({
              type: 'success',
              message: 'Enable successfully'
            });
          },
          onError(error: any) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };

    const disableComment = ({ commentId }: CommentIdOptions, index: Number) => {
      statusIndex.value = index;
      disableMutate(
        { commentId },
        {
          onSuccess() {
            refetch.value();

            ElMessage({
              type: 'success',
              message: 'Enable successfully'
            });
          },
          onError(error: any) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };

    return {
      page,
      data,
      isLoading,
      isFetching,
      statusIndex,
      CommentStatus,
      isDeletedLoading,
      isEnableLoading,
      FILTER_OPTIONS,
      isDisableLoading,
      sortChange,
      searchKeyword,
      enableComment,
      deleteComment,
      disableComment,
      t,
      handleFilterChange
    };
  }
});
